<template>
    <div class="main">
        <Title class="title">疫情防控，历思公益在行动！疫情期间，免费提供酒精、消毒液等防疫用品...</Title>
        <p class="time">2019-01-31 11:31</p>
        <ImagePreview  class="more_img" :src="require('../../assets/Duty/public/2-1.png')" :srcList="[require('../../assets/Duty/public/2-1.png')]"></ImagePreview>

        <Pgp>
            当前，新型冠状病毒肺炎疫情防控仍处于关键时期，福建历思司法鉴定所（以下简称“历思”）积极响应国家号召，以实际行动支持防疫攻坚战。
        </Pgp>
        <ImagePreview  class="s_img" :src="require('../../assets/Duty/public/2-2.png')" :srcList="[require('../../assets/Duty/public/2-2.png')]"></ImagePreview>
        <Pgp>供免费防疫用品</Pgp>
        <Pgp>自新冠疫情爆发后，历思已组织过一次免费提供防疫用品的公益活动。近期经过筹备，历思将再次向大家免费提供消毒液、酒精等防疫用品，为抗击疫情尽自己的一点心力。欢迎大家前来领取！领取地址：厦门湖里大道33号7层（联发集团大门保安亭旁）联系电话：0592-2283132</Pgp>
        <ImagePreview  class="l_img" :src="require('../../assets/Duty/public/2-3.jpg')" :srcList="[require('../../assets/Duty/public/2-3.jpg')]"></ImagePreview>
        <Pgp>开展司法鉴定公益咨询</Pgp>
        <Pgp><pre style="font-family:initial;">
为服务疫情防控，做好司法鉴定保障工作，历思已安排值班人员，落实防控措施，坚守岗位，确保能够随时响应需要。

在疫情期间，我们将免费提供司法鉴定公益咨询，大家可通过电话、微信等方式进行沟通咨询。

咨询电话：18959633132/ 0592-2283132

咨询公众号：福建历思司法鉴定所</pre></Pgp>
        <ImagePreview  class="l_img" :src="require('../../assets/Duty/public/2-4.png')" :srcList="[require('../../assets/Duty/public/2-4.png')]"></ImagePreview>
        <Pgp>(快来关注我，咨询一下~）</Pgp>
        <ImagePreview  class="s_img" :src="require('../../assets/Duty/public/2-2.png')" :srcList="[require('../../assets/Duty/public/2-2.png')]"></ImagePreview>
        <Pgp>尽力服务客户紧急需求</Pgp>
        <Pgp>疫情期间，为了能够急客户之所急，服务好客户一些比较急的需求，我们将采取线上受理、线下错开办理等方式，灵活地提供司法鉴定服务，鉴定报告以在线或邮寄方式为主，在这特殊时期，实现“不见面”也能“办成事”，减少不必要的人员聚集。</Pgp>
        <ImagePreview  class="more_img" :src="require('../../assets/Duty/public/2-5.jpg')" :srcList="[require('../../assets/Duty/public/2-5.jpg')]"></ImagePreview>
        <Pgp>
            <pre style="font-family:initial;">疫情防控，历思在行动

抗击疫情，人人有责历思将一如既往做好司法鉴定服务与您一道打赢这场抗击疫情的战斗</pre>
        </Pgp>
        <ImagePreview  class="more_img" :src="require('../../assets/Duty/public/2-6.png')" :srcList="[require('../../assets/Duty/public/2-6.png')]"></ImagePreview>
        <ImagePreview  class="L_img" :src="require('../../assets/Duty/public/2-7.jpg')" :srcList="[require('../../assets/Duty/public/2-7.jpg')]"></ImagePreview>
        <Pgp>（本文部分图文来源于网络，如有侵权立即联系 我们将立即处理）</Pgp>

        <div class="line"></div>
        <div class="footer">
            <div class="left">
                <p class="item">上一篇：<router-link :to="'/duty/public_detail/1'">【我为群众办实事】福建历思司法鉴定</router-link></p>
                <p class="item">下一篇：无</p>
            </div>
            <div class="right">
                <el-button type="primary" @click="goBack">返回列表</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            img_data:['/1-2.jpg','/1-3.jpg']
        }
    },
    mounted(){
        console.log(this.$route)
        // switch(this.id){
        //     case '1':

        //     break;
        // }

    },
    computed:{
        id(){
            return this.$route.params.id
        },
    },
    methods:{
        goBack(){
            this.$store.commit('set_path',{path:'/duty/public',query:{}})
        }
    }
}
</script>
<style scoped src="./publicDetail.css"></style>
<style scoped>